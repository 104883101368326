////////////////////////////////////////////////////////////////
//////////////// DO NOT MODIFY INCLUDE ORDER ///////////////////
////////////////////////////////////////////////////////////////


@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Light.eot');
  src: url('ProximaNova-Light.eot?#iefix') format('embedded-opentype'),
  url('ProximaNova-Light.woff2') format('woff2'),
  url('ProximaNova-Light.woff') format('woff'),
  url('ProximaNova-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Extrabld.eot');
  src: url('ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
  url('ProximaNova-Extrabld.woff2') format('woff2'),
  url('ProximaNova-Extrabld.woff') format('woff'),
  url('ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Semibold.eot');
  src: url('ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('ProximaNova-Semibold.woff2') format('woff2'),
  url('ProximaNova-Semibold.woff') format('woff'),
  url('ProximaNova-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Bold.eot');
  src: url('ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('ProximaNova-Bold.woff2') format('woff2'),
  url('ProximaNova-Bold.woff') format('woff'),
  url('ProximaNova-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNovaT-Thin.eot');
  src: url('ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'),
  url('ProximaNovaT-Thin.woff2') format('woff2'),
  url('ProximaNovaT-Thin.woff') format('woff'),
  url('ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Black.eot');
  src: url('ProximaNova-Black.eot?#iefix') format('embedded-opentype'),
  url('ProximaNova-Black.woff2') format('woff2'),
  url('ProximaNova-Black.woff') format('woff'),
  url('ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('ProximaNova-Regular.eot');
  src: url('ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('ProximaNova-Regular.woff2') format('woff2'),
  url('ProximaNova-Regular.woff') format('woff'),
  url('ProximaNova-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}


$primary-color: #2C3E50 !default;
$primary-font-color: #2C3E50 !default;
$secondary-color: #F89D2A !default;
$secondary-font-color: #000000 !default;

$accent-color: #f06c2f !default;
$light-color: rgba(212, 163, 104, 0.1) !default;
$highlight-color: #663A13 !default;
$background-color: #ffffff !default;
$light-bg: #F7F7F5 !default;

$border-radius: 4px !default;
$border-color: #EDEDED !default;

$text: #FBFBFD !default;
$text-body: #565656 !default;
$body-light-text: #737373 !default;
$light-text: #959595 !default;
$light-text-on-dark: #c0c0c0 !default;
$headings-text: inherit !default;

$white: #ffffff !default;
$black: #000000 !default;
$dark: #283618 !default;
$gray: #F4F4F4 !default;
$darkgray: #777777 !default;
$brown: #ac877c !default;
$border: #ebebeb !default;
$blue: #007bff !default;
$yellow: #fff102 !default;
$green: #28a745 !default;
$red: #f44336 !default;
$grey: #f5f6f7 !default;

$warning: #ffc107 !default;
$danger: #f44336 !default;
$success: #4caf50 !default;


$font-size: 1.1rem !default;
$font-heading-size: 2.8rem !default;
$font-weight: 400 !default;
$line-height: 1.8 !default;

$button-font-size: 1.05rem !default;
$button-font-weight: 400 !default;
$infinite: 99999999px;

$box-shadow: -2px 13px 53px -13px rgba(0, 0, 0, 0.15);
$box-shadow-hover: 0 .25rem 1rem rgba(0, 0, 0, 0.2);

$primary-family: 'Proxima Nova', sans-serif !default;


@mixin anchor() {
  a {
    @content;

    &:hover {
      @content;
    }

    &:active {
      @content;
    }

    &:visited {
      @content;
    }

    &:focus {
      @content;
    }
  }
}

@mixin input() {
  button,
  input,
  input.form-control,
  select,
  select.form-control,
  textarea,
  textarea.form-control {
    @content;

    &:hover {
      @content;
    }

    &:active {
      @content;
    }

    &:visited {
      @content;
    }

    &:focus {
      @content;
    }
  }
}

@mixin clearfix {
  &::after {
    display: block;
    clear: both;
    content: '';
  }
}

@mixin mq($breakpoint) {
  @if $breakpoint == "x33" {
    @media (min-width: 330px) {
      @content;
    }
  } @else if $breakpoint == "mx34" {
    @media (max-width: 340px) {
      @content;
    }
  } @else if $breakpoint == "x34" {
    @media (min-width: 340px) {
      @content;
    }
  } @else if $breakpoint == "mx34" {
    @media (max-width: 340px) {
      @content;
    }
  } @else if $breakpoint == "x35" {
    @media (min-width: 350px) {
      @content;
    }
  } @else if $breakpoint == "mx35" {
    @media (max-width: 350px) {
      @content;
    }
  } @else if $breakpoint == "x4" {
    @media (min-width: 400px) {
      @content;
    }
  } @else if $breakpoint == "mx4" {
    @media (max-width: 400px) {
      @content;
    }
  } @else if $breakpoint == "x5" {
    @media (min-width: 500px) {
      @content;
    }
  } @else if $breakpoint == "mx5" {
    @media (max-width: 500px) {
      @content;
    }
  } @else if $breakpoint == "msm" {
    @media (max-width: 574px) {
      @content;
    }
  } @else if $breakpoint == "sm" {
    @media (min-width: 575px) {
      @content;
    }
  } @else if $breakpoint == "smmd" {
    @media (min-width: 575px) and (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "x6" {
    @media (min-width: 600px) {
      @content;
    }
  } @else if $breakpoint == "mx6" {
    @media (max-width: 640px) {
      @content;
    }
  } @else if $breakpoint == "x7" {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == "mx7" {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "md" {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $breakpoint == "mmd" {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $breakpoint == "mdxl" {
    @media (min-width: 768px) and (max-width: 1199px) {
      @content;
    }
  } @else if $breakpoint == "mdlg" {
    @media (min-width: 768px) and (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == "x8" {
    @media (min-width: 801px) {
      @content;
    }
  } @else if $breakpoint == "mx8" {
    @media (max-width: 800px) {
      @content;
    }
  } @else if $breakpoint == "x9" {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $breakpoint == "mx9" {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == "lg" {
    @media (min-width: 992px) {
      @content;
    }
  } @else if $breakpoint == "mlg" {
    @media (max-width: 991px) {
      @content;
    }
  } @else if $breakpoint == "lgxl" {
    @media (min-width: 992px) and (max-width: 1280px) {
      @content;
    }
  } @else if $breakpoint == "w0" {
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $breakpoint == "mw0" {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $breakpoint == "w1" {
    @media (min-width: 1100px) {
      @content;
    }
  } @else if $breakpoint == "mw1" {
    @media (max-width: 1099px) {
      @content;
    }
  } @else if $breakpoint == "xl" {
    @media (min-width: 1200px) {
      @content;
    }
  } @else if $breakpoint == "mxl" {
    @media (max-width: 1199px) {
      @content;
    }
  } @else if $breakpoint == "w2" {
    @media (min-width: 1281px) {
      @content;
    }
  } @else if $breakpoint == "mw2" {
    @media (max-width: 1280px) {
      @content;
    }
  } @else if $breakpoint == "w3" {
    @media (min-width: 1367px) {
      @content;
    }
  } @else if $breakpoint == "mw3" {
    @media (max-width: 1366px) {
      @content;
    }
  } @else if $breakpoint == "w4" {
    @media (min-width: 1441px) {
      @content;
    }
  } @else if $breakpoint == "mw4" {
    @media (max-width: 1440px) {
      @content;
    }
  } @else if $breakpoint == "w6" {
    @media (min-width: 1681px) {
      @content;
    }
  } @else if $breakpoint == "mw6" {
    @media (max-width: 1680px) {
      @content;
    }
  } @else if $breakpoint == "w9" {
    @media (min-width: 1921px) {
      @content;
    }
  } @else if $breakpoint == "mw9" {
    @media (max-width: 1920px) {
      @content;
    }
  } @else if $breakpoint == "k2" {
    @media (min-width: 2561px) {
      @content;
    }
  } @else if $breakpoint == "mwk2" {
    @media (max-width: 2560px) {
      @content;
    }
  } @else if $breakpoint == "k4" {
    @media (min-width: 3840px) {
      @content;
    }
  } @else if $breakpoint == "mwk4" {
    @media (max-width: 3839px) {
      @content;
    }
  }
}

.container-fluid {
  max-width: 1920px;
  @include mq(md) {
    padding: 0 5%;
  }
}

.container,
.container-md {
  @include mq(w4) {
    max-width: 1300px;
  }
}

.badge {
  min-width: 54px;
}


.arrow-center {
  .owl-nav {
    button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      outline: 0;
    }

    .owl-prev {
      left: -6rem;
    }

    .owl-next {
      right: -6rem;
    }

    @include mq(mxl) {
      .owl-prev {
        left: 0rem;
      }

      .owl-next {
        right: 0rem;
      }
    }
  }
}

%Container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @include mq(sm) {
    max-width: 540px;
  }
  @include mq(md) {
    max-width: 720px;
  }
  @include mq(lg) {
    max-width: 960px;
  }
  @include mq(xl) {
    max-width: 1140px;
  }
}

%ObjectCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

%ObjectContain {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

%ResetButton {
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}

%SrOnly {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

%Fa {
  display: inline-block;
  font: normal normal normal 100%/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%Auto {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}

%Shadow {
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.2);
}

%Button {
  @extend %ResetButton;
  display: inline-block;
  position: relative;
  z-index: 0;
  text-align: center;
  background-color: $secondary-color;
  color: $white;
  border-color: $primary-color;
  border-radius: $border-radius;
  border: solid 1px transparent;
  font-family: $primary-family;
  font-size: $button-font-size;
  font-weight: $button-font-weight;
  line-height: 1;
  transition: .4s all ease;

  &:focus,
  &:active {
    box-shadow: none;
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }

  &:hover {
    box-shadow: none;
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }
}

%Input {
  @extend %ResetButton;
  height: 2.2rem;
  font-size: .9rem;
  padding-left: 1rem;
  padding-right: 1rem;
  appearance: none;
  border-radius: $border-radius;
  border: solid 1px $black;
  color: $text;
  @include mq(md) {
    height: 2.4rem;
  }
  @include mq(xl) {
    height: 2.6rem;
  }

  &::placeholder {
    color: $light-text;
  }

  &:focus,
  &:active {
    border-color: $primary-color;
  }

  &.invalid {
    border-color: $danger;
  }
}

html {
  font-size: .9rem;
}

body {
  margin: 0;
  font-family: $primary-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $primary-font-color;
  font-size: 1rem;
  font-weight: 300;
  line-height: $line-height;

  @include mq(md) {
    font-size: 1.15rem
  }
}

p {
  color: $black;
}

@include anchor() {
  text-decoration: none;
  outline-width: 0;
}

@mixin input() {
  outline: none;
  outline-width: 0;
  box-shadow: none;
  border: none;
}

a {
  color: $primary-color;

  &:hover {
    color: $secondary-font-color;
  }

  path {
    transition: all 0.2s ease;
  }
}

img {
  max-width: 100%;
  height: auto;
}

b {
  font-weight: 600;
}

strong {
  font-weight: 700;
}

hr {
  border-color: $black;
}

sup {
  font-size: 80%;
}

fieldset {
  label {
    font-weight: 600;
    margin-bottom: .25rem;
  }
}

.d-flex-parent > * {
  display: flex;
}

.flex-wrap-parent > * {
  flex-wrap: wrap;
}

.bk-page-nav {
  background-color: $white;
  text-align: center;

  a {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.font-weight-semibold {
  font-weight: 600;
}

::selection {
  color: $white;
  background-color: $primary-color;
}

@mixin cmmn_spacing() {
  padding: 3rem 0rem;

  @include mq(md) {
    padding: 4rem 0rem;
  }

  @include mq(xl) {
    padding: 6em 0rem;
  }

  @include mq(w6) {
    padding: 7rem 0rem;
  }

}

.spacing {
  @include cmmn_spacing;
}

@mixin cmmn_spacing_top() {
  padding-top: 3rem;

  @include mq(md) {
    padding-top: 4rem;
  }

  @include mq(xl) {
    padding-top: 6em;
  }

  @include mq(w6) {
    padding-top: 7rem;
  }
}

.spacing-top {
  @include cmmn_spacing_top;
}

@mixin cmmn_spacing_bottom() {
  padding-bottom: 3rem;

  @include mq(md) {
    padding-bottom: 4rem;
  }

  @include mq(xl) {
    padding-bottom: 6em;
  }

  @include mq(w6) {
    padding-bottom: 7rem;
  }
}

.spacing-bottom {
  @include cmmn_spacing_bottom;
}

.header-space {
  height: 86px;
  @include mq(sm) {
    height: 100px;
  }
}

@keyframes flare {
  0% {
    width: 0;
    left: -10%;
  }

  50% {
    width: 50%;
  }

  100% {
    width: 0;
    left: 110%;
  }
}

.btn {
  @extend %Button;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  min-width: 8rem;
  padding: 1.1rem 2rem;
  text-transform: capitalize;

  path {
    transition: .4s all ease;
  }

  &:hover {
    path {
      fill: $primary-color;
    }
  }
}

.white-btn {
  background-color: $white;
  border: none;
  color: $primary-color;

  path {
    fill: $primary-color;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: $secondary-color;
    color: $white;

    path {
      fill: $white;
    }
  }
}

.bk-btn {
  position: relative;
  max-width: 10rem;
  max-width: max-content;

  &:before {
    content: "";
    position: absolute;
    width: 3.8rem;
    height: 3.8rem;
    background-color: $secondary-color;
    left: -12px;
    top: -12px;
    z-index: 1;
  }

  .btn {
    z-index: 2;
  }
}


a {
  transition: all 0.2s ease;
}

.btn-border {
  background-color: transparent;
  color: $primary-color;
  border-color: $primary-color;

  &:active,
  &:focus {
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }

  &:hover {
    background-color: $primary-color;
    color: $white;
    border-color: $primary-color;
  }
}

.btn-light {
  background-color: $white;
  color: $primary-color;
  border-color: $white;

  &:active,
  &:focus {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  &:hover {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}

.btn-light.btn-border {
  background-color: transparent;
  color: $white;
  border-color: $white;

  &:active,
  &:focus {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }

  &:hover {
    background-color: transparent;
    color: $white;
    border-color: $white;
  }
}

// .btn-secondary{
//   padding-left: 1.5rem;
//   padding-right: 1.5rem;
//   text-transform: uppercase;
//   @include mq(sm){
//     padding-left: 2rem;
//     padding-right: 2rem;
//   }
//   @include mq(md){
//     padding-left: 2.5rem;
//     padding-right: 2.5rem;
//   }
//   @include mq(xl){
//     padding-left: 3rem;
//     padding-right: 3rem;
//   }
//
//   &:hover{
//     &::before{
//       transform: skewX(-25deg);
//       animation: flare 2s linear infinite;
//     }
//   }
//
//   &::after{
//     display: none;
//   }
//
//   &::before{
//     display: block;
//     width: 0%;
//     height: 100%;
//     content: '';
//     position: absolute;
//     top: 0;
//     left: -10%;
//     z-index: -1;
//     background: -webkit-linear-gradient(left,rgba(255,255,255,0) 0%,rgba(255,255,255,.1) 80%,rgba(255,255,255,0) 100%);
//     background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.1) 80%,rgba(255,255,255,0) 100%);
//     transform: skewX(-25deg);
//     transition: all .4s ease;
//   }
// }
// .btn-light.btn-secondary{
//   &::before{
//     background: -webkit-linear-gradient(left,rgba(45,70,57,0) 0%,rgba(45,70,57,.1) 80%,rgba(45,70,57,0) 100%);
//     background: linear-gradient(to right,rgba(45,70,57,0) 0%,rgba(45,70,57,.1) 80%,rgba(45,70,57,0) 100%);
//   }
// }

.btn-primary {
  background-color: $highlight-color;
  border-color: $highlight-color;
  color: $secondary-font-color;

  &:active,
  &:focus {
    background-color: transparent;
    color: $highlight-color;
    border-color: $highlight-color;
  }

  &:hover {
    background-color: transparent;
    color: $highlight-color;
    border-color: $highlight-color;
  }
}

.btn-secondary {
  background-color: $secondary-font-color;
  border-color: $secondary-font-color;
  color: $highlight-color;

  &:active,
  &:focus {
    background-color: transparent;
    color: $highlight-color;
    border-color: $highlight-color;
  }

  &:hover {
    background-color: transparent;
    color: $highlight-color;
    border-color: $highlight-color;
  }
}

.btn-link {
  color: $primary-color;
  text-decoration: underline;

  &:hover {
    color: $secondary-font-color;
  }
}

@keyframes right {
  0% {
    transform: translateX(0);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  60% {
    transform: translateX(10px);
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.modal-dialog {
  margin: 2.75rem auto;

  &.dialog-form {
    max-width: 650px;
  }

  &.dialog-content {
    max-width: 750px;
  }

  .modal-content {
    border-radius: $border-radius;
  }

  .modal-body {
    padding: 2.5rem 2rem 2rem;
  }

  header {
    p {
      margin-top: -1rem;
    }
  }
}

.bk-breadcrumb {
  display: flex;
  align-items: center;

  ul {
    padding-left: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    flex-wrap: wrap;
  }

  li {
    list-style: none;
    margin-right: 0.8rem;
    position: relative;
    padding-right: 1.2rem;

    &:after {
      content: "";
      background: url("./right-arrow.svg") no-repeat center / 8px;
      width: 8px;
      height: 11px;
      position: absolute;
      right: 0;
      top: 9px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;

      &:after {
        display: none;
      }
    }
  }

  a {
    font-size: 1rem;
    font-weight: 400;

    &:hover {
      color: $secondary-color;
    }
  }
}

.bk-pagination {

  ul {
    padding-left: 0;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: flex-start;
  }

  li {
    list-style: none;
    margin: 0 6px;

    &:first-child {
      margin-left: 0;
      margin-right: 10px;
    }

    &:last-child {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  a {
    font-size: 1rem;
    font-weight: 400;
  }
}

.h1 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.15;
  text-transform: capitalize;
  @include mq(sm) {
    font-size: 2.5rem;
  }
  @include mq(lg) {
    font-size: 3.5rem;
  }
  @include mq(xl) {
    font-size: 3.9rem;
  }
}

h1 {
  @extend .h1;
}

.h1, .h2, .h3 {
  margin-bottom: 1rem;
}

.h2 {
  font-size: 2.1rem;
  font-weight: 600;
  line-height: 1.2;
  @include mq(sm) {
    font-size: 2.5rem;
  }
  @include mq(md) {
    font-size: 2.9rem;
  }
  @include mq(xl) {
    font-size: 3.5rem;
  }
}

h2 {
  @extend .h2;
}

.h3 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.25;
  @include mq(md) {
    font-size: 1.7rem;
  }
  @include mq(lg) {
    font-size: 2.2rem;
  }
}

h3 {
  @extend .h3;
}

.border-radius {
  border-radius: $border-radius;
}

.radius {
  border-radius: $border-radius;
}

.img-border {
  border: 4px solid $secondary-font-color;
  overflow: hidden;
}

.border-top-primary {
  border-top: 1px solid $primary-color;
}

.border-bottom-primary {
  border-bottom: 1px solid $primary-color;
}

@for $i from 0 through 10 {
  .radius-#{$i} {
    border-radius: $border-radius+(.3*$i);
  }
}

.bk-obj-cover {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 0;
  position: relative;

  img {
    @extend %ObjectCover;
  }
}

.no-gutters {
  .bk-obj-md-cover {
    @include mq(md) {
      left: 0;
      right: 0;
    }
  }
}

.bk-obj-md-cover {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 80%;
  position: relative;
  z-index: 0;

  @include mq(md) {
    width: auto;
    height: 100%;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
  }

  img {
    @extend %ObjectCover;
  }
}

.bk-obj-contain {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 0;
  position: relative;

  img {
    @extend %ObjectContain;
  }
}

.bk-obj-md-cover {
  width: 100%;
  margin-bottom: 0;
  padding-bottom: 80%;
  position: relative;
  z-index: 0;

  @include mq(md) {
    width: auto;
    height: 100%;
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
  }

  img {
    @extend %ObjectContain;
  }
}

blockquote {
  font-size: 1.2rem;
  font-style: italic;
  margin-bottom: 0;

  p {
    display: inline-block;
    margin-bottom: 0;
  }

  cite {
    font-family: $primary-family;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
  }
}

.relative {
  position: relative;
}

.position-inherit {
  position: inherit;
}

.img-w-100 {
  img {
    width: 100%;
  }
}

// Spacing Margin
@for $i from 0 through 10 {
  .mgt-#{$i} {
    margin-top: #{$i}rem;
  }
  .mgb-#{$i} {
    margin-bottom: #{$i}rem;
  }
  .mgl-#{$i} {
    margin-left: #{$i}rem;
  }
  .mgr-#{$i} {
    margin-right: #{$i}rem;
  }

  .mgt--#{$i} {
    margin-top: -#{$i}rem;
  }
  .mgb--#{$i} {
    margin-bottom: -#{$i}rem;
  }
  .mgl--#{$i} {
    margin-left: -#{$i}rem;
  }
  .mgr--#{$i} {
    margin-right: -#{$i}rem;
  }
}

@include mq('sm') {
  @for $i from 0 through 10 {
    .mgt-sm-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-sm-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-sm-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-sm-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-sm--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-sm--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-sm--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-sm--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('md') {
  @for $i from 0 through 10 {
    .mgt-md-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-md-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-md-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-md-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-md--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-md--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-md--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-md--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 10 {
    .mgt-lg-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-lg-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-lg-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-lg-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-lg--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-lg--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-lg--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-lg--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 10 {
    .mgt-xl-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-xl-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-xl-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-xl-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-xl--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-xl--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-xl--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-xl--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 10 {
    .mgt-xxl-#{$i} {
      margin-top: #{$i}rem;
    }
    .mgb-xxl-#{$i} {
      margin-bottom: #{$i}rem;
    }
    .mgl-xxl-#{$i} {
      margin-left: #{$i}rem;
    }
    .mgr-xxl-#{$i} {
      margin-right: #{$i}rem;
    }

    .mgt-xxl--#{$i} {
      margin-top: -#{$i}rem;
    }
    .mgb-xxl--#{$i} {
      margin-bottom: -#{$i}rem;
    }
    .mgl-xxl--#{$i} {
      margin-left: -#{$i}rem;
    }
    .mgr-xxl--#{$i} {
      margin-right: -#{$i}rem;
    }
  }
}

// Spacing Padding

@for $i from 0 through 10 {
  .pdt-#{$i} {
    padding-top: #{$i}rem;
  }
  .pdb-#{$i} {
    padding-bottom: #{$i}rem;
  }
  .pdl-#{$i} {
    padding-left: #{$i}rem;
  }
  .pdr-#{$i} {
    padding-right: #{$i}rem;
  }

  .pdt--#{$i} {
    padding-top: -#{$i}rem;
  }
  .pdb--#{$i} {
    padding-bottom: -#{$i}rem;
  }
  .pdl--#{$i} {
    padding-left: -#{$i}rem;
  }
  .pdr--#{$i} {
    padding-right: -#{$i}rem;
  }

  .pd-#{$i} {
    padding: #{$i}rem;
  }
}

@include mq('md') {
  @for $i from 0 through 10 {
    .pdt-md-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-md-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-md-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-md-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-md--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-md--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-md--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-md--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-md-#{$i} {
      padding: #{$i}rem;
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 10 {
    .pdt-lg-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-lg-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-lg-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-lg-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-lg--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-lg--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-lg--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-lg--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-lg-#{$i} {
      padding: #{$i}rem;
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 10 {
    .pdt-xl-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-xl-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-xl-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-xl-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-xl--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-xl--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-xl--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-xl--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-xl-#{$i} {
      padding: #{$i}rem;
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 10 {
    .pdt-xxl-#{$i} {
      padding-top: #{$i}rem;
    }
    .pdb-xxl-#{$i} {
      padding-bottom: #{$i}rem;
    }
    .pdl-xxl-#{$i} {
      padding-left: #{$i}rem;
    }
    .pdr-xxl-#{$i} {
      padding-right: #{$i}rem;
    }

    .pdt-xxl--#{$i} {
      padding-top: -#{$i}rem;
    }
    .pdb-xxl--#{$i} {
      padding-bottom: -#{$i}rem;
    }
    .pdl-xxl--#{$i} {
      padding-left: -#{$i}rem;
    }
    .pdr-xxl--#{$i} {
      padding-right: -#{$i}rem;
    }

    .pd-xxl-#{$i} {
      padding: #{$i}rem;
    }
  }
}


@for $i from 0 through 10 {
  .size-#{$i} {
    font-size: $font-size + (.1*$i);
  }
}

@include mq('md') {
  @for $i from 0 through 10 {
    .size-md-#{$i} {
      font-size: $font-size + (.1*$i);
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 10 {
    .size-lg-#{$i} {
      font-size: $font-size + (.1*$i);
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 10 {
    .size-xl-#{$i} {
      font-size: $font-size + (.1*$i);
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 10 {
    .size-xxl-#{$i} {
      font-size: $font-size + (.1*$i);
    }
  }
}

// Spacing Margin
@for $i from 0 through 30 {
  .gutter-#{$i} {
    margin-left: -#{$i}px;
    margin-right: -#{$i}px;

    & > div[class*="col"] {
      padding-left: #{$i}px;
      padding-right: #{$i}px;
    }
  }
}

@include mq('sm') {
  @for $i from 0 through 30 {
    .gutter-sm-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      & > div[class*="col"] {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('md') {
  @for $i from 0 through 30 {
    .gutter-md-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      & > div[class*="col"] {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 30 {
    .gutter-lg-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      & > div[class*="col"] {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 30 {
    .gutter-xl-#{$i} {
      margin-left: -#{$i}px;
      margin-right: -#{$i}px;

      & > div[class*="col"] {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 30 {
    .gutter-xxl-#{$i} {
      margin: -#{$i}px;

      & > div[class*="col"] {
        padding-left: #{$i}px;
        padding-right: #{$i}px;
      }
    }
  }
}

// Spacing Margin
@for $i from 0 through 20 {
  .mxw-#{$i} {
    max-width: #{$i * 50}px;
  }
}

@include mq('sm') {
  @for $i from 0 through 20 {
    .mxw-sm-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('md') {
  @for $i from 0 through 20 {
    .mxw-md-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('lg') {
  @for $i from 0 through 20 {
    .mxw-lg-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('xl') {
  @for $i from 0 through 20 {
    .mxw-xl-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

@include mq('xxl') {
  @for $i from 0 through 20 {
    .mxw-xl-#{$i} {
      max-width: #{$i * 50}px;
    }
  }
}

.background-primary {
  background-color: $primary-color;
}

.background-secondary {
  background-color: $secondary-color;
}

.background-gray {
  background-color: $gray;
}

.background-darkgray {
  background-color: $darkgray;
}

.background-white {
  background-color: $white;
}

.background-black {
  background-color: $white;
}

.background-light {
  background-color: $light-bg;
}

.color-primary {
  color: $primary-color;
}

.color-secondary {
  color: $secondary-color;
}

.color-black {
  color: $black;
}

.color-white {
  color: $white;
}

.color-dark {
  color: $dark;
}

.color-highlight {
  color: $highlight-color;
}

.color-text {
  color: $text;
}

// Spacing Margin
@for $i from 0 through 10 {
  .obj-h-#{$i * 10} {
    padding-bottom: #{$i * 10%};
  }
}

@include mq(sm) {
  @for $i from 0 through 10 {
    .obj-sm-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(md) {
  @for $i from 0 through 10 {
    .obj-md-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(lg) {
  @for $i from 0 through 10 {
    .obj-lg-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(xl) {
  @for $i from 0 through 10 {
    .obj-xl-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}

@include mq(xxl) {
  @for $i from 0 through 10 {
    .obj-xxl-h-#{$i * 10} {
      padding-bottom: #{$i * 10%};
    }
  }
}


.bk-header {
  width: 100%;
  background-color: transparent;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2;
  transition: 0.4s;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  padding: 1rem 0rem;

  &.fixed {
    .header-bk {
      position: fixed;
    }
  }

  &.active {
    left: -280px;

    @include mq(xl) {
      left: 0;
    }
  }

  a {

    &:not(.btn):not(.obj):not(.button) {
      &::after {
        display: none;
      }
    }
  }

  nav {
    a {
      text-transform: uppercase;
      font-size: 1rem;
      position: relative;
      color: $white;

      &:after {
        content: attr(data-title);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        color: $primary-color;
        display: block !important;
        font-weight: 400;
        white-space: nowrap;
        transition: all .4s ease;
      }

      &:hover {
        &:after {
          font-weight: 700;
        }
      }
    }

    & > ul {
      list-style: none;
      margin: 0;
      padding: 0;

      & > li {
        margin: 0;
        padding: 1rem 1.2rem;
        position: relative;
        display: flex;
        align-items: center;

        &.current_page_item {
          a {
            &:after {
              font-weight: 700;
            }
          }
        }

        &.menu-item-has-children {
          &:hover {
            ul.sub-menu {
              display: block;
            }
          }
        }
      }
    }
  }

  .logo-bk {
    a {
      display: block;
    }

    img {
      width: auto;
      height: 4rem;

      @include mq(sm) {
        height: 5rem;
      }
    }
  }


  &.fixed-top {
    &:before {
      display: none;
    }

    position: fixed;
    width: 100%;
    background-color: #fff;
    -webkit-animation: 0.7s fade-in;
    animation: 0.7s fade-in;
    z-index: 999;
    animation: 0.7s ease-in-out 0.1s normal both 1 running stickyhead;
    box-shadow: $box-shadow-hover;
    padding: 0.5rem 0rem;

    .bk-mob-tog {
      color: $primary-color;

      .bk-burger-menu {

        &:before,
        &:after {
          background-color: $primary-color;
        }
      }
    }
  }

  .right-bk {
    a {
      margin-right: 2rem;

      &:last-child {
        margin-right: 0;
      }
    }

    @include mq(mlg) {
      margin-left: auto;
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(10px);
    opacity: 0.2;
  }

  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}


@keyframes stickyhead {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.bk-header {
  background-color: $white;

  li.menu-item-has-children {
    ul.sub-menu {
      display: none;
      white-space: nowrap;
      min-width: 16rem;
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: left;
      font-weight: 400;
      background-color: $primary-color;
      box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1);
      position: absolute;
      top: 100%;
      left: 0;
      margin: 0;
      animation: slide .2s ease;
      padding: 1rem;
      border-radius: $border-radius;

      li {
        width: 100%;
        margin: 0;
        padding: 0.25rem 0;
        position: relative;
        transition: 0.4s;

        &.active {
          a {
            color: $secondary-color;
          }
        }

        a {
          display: block;
          padding: 0.5rem 1rem;
          color: $white;
          font-weight: 400;

          &:hover {
            color: $secondary-color;
          }

          &::after {
            display: none;
          }
        }

        ul {
          display: none;
          min-width: 12rem;
          margin: 0;
          padding: 0;
          list-style: none;
          position: absolute;
          top: 0;
          left: 100%;
          transform: translateX(-1px);
          background-color: $white;
          box-shadow: 0 0.15rem 0.25rem rgba(0, 0, 0, 0.1);
        }
      }
    }
  }
}

.bk-wrapper {
  width: 100%;
  overflow: hidden;

  &.active {
    .bk-ui {
      right: 280px;
      @include mq(xl) {
        right: 0;
      }
    }
  }

  .bk-ui {
    position: relative;
    right: 0;
    transition: .4s;
  }
}

.bk-mob-tog {
  position: relative;
  height: 3rem;
  margin-left: .8rem;
  padding: 0 0 0 2rem;
  outline: 0 !important;
  color: $primary-color;
  background-color: transparent;
  box-shadow: none !important;
  border: none;

  @include mq(sm) {
    margin-left: 1rem;
    padding-left: 2rem;
  }

  &.active {
    .bk-burger-menu {
      &::before {
        transform: translate(-50%, -50%) translateY(0) rotate(-45deg);
      }

      &::after {
        transform: translate(-50%, -50%) translateY(0) rotate(45deg);
      }
    }
  }

  .bk-burger-menu {
    width: 2.2rem;
    height: .4rem;
    position: absolute;
    left: 0;
    top: 17px;

    &::before,
    &::after {
      width: .8rem;
      height: 1px;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: $primary-color;
      transition: transform .4s;
      @include mq(sm) {
        width: 1.2rem;
        height: 2px;
      }
    }

    &::before {
      transform: translate(-50%, -50%) translateY(-2px) rotate(0);
      @include mq(sm) {
        transform: translate(-50%, -50%) translateY(-3px) rotate(0);
      }
    }

    &::after {
      transform: translate(-50%, -50%) translateY(2px) rotate(0);
      @include mq(sm) {
        transform: translate(-50%, -50%) translateY(3px) rotate(0);
      }
    }
  }

  span {
    display: block;
    text-transform: uppercase;
    font-size: .8rem;
    font-weight: 600;
    @include mq(sm) {
      font-size: .9rem;
      margin-left: 1.5rem;
    }
  }
}

.bk-mob-nav-mask {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 320;
  transform: translateX(-100%);
  width: 100%;
  height: 100%;
  transition: .4s;

  &.active {
    transform: translateX(0);
  }
}

.bk-mob-nav {
  background-color: $primary-color;
  color: $primary-font-color;
  width: 280px;
  height: 100%;
  position: fixed;
  top: 0;
  right: -280px;
  z-index: 321;
  transition: 0.4s;
  z-index: 999999;

  &.active {
    right: 0;
  }

  .contain-bk {
    width: 100%;
    height: 100%;
    padding: 2rem;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    .btn {
      padding: 1.1rem 1.2rem;
    }
  }

  a {
    display: inline-block;
    color: $white;

    &::after {
      background-color: $white;
    }

    &:hover {
      color: $white;
    }
  }

  .list-bk {
    margin-bottom: 2rem;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        position: relative;
        margin: 0;
        padding: .5rem 0;

        &:first-child {
          border-top: none;
        }

        a {
          position: relative;
          padding: .25rem 0;
          font-size: 1rem;
          font-weight: 500;
          text-transform: uppercase;
        }

        button {
          position: absolute;
          right: 0;
          top: 13px;
          background-color: transparent;
          border: none;
          outline: 0;
          width: 16px;
          height: 16px;

          &:after {
            content: "";
            background: url("./right-arrow-angle.svg") no-repeat center / 8px;
            position: absolute;
            left: 0;
            top: 0;
            transition: all .2s ease;
            width: 16px;
            height: 16px;
          }
        }

        ul {
          display: none;
          background-color: rgba(255, 255, 255, 0.1);
          color: $primary-font-color;
          padding: 1rem;

          li {
            border-top: none;

            button {
              background-color: $primary-color;
              color: $primary-font-color;
            }

            ul {
              margin-left: 1rem;
              background-color: $primary-color;
              color: $primary-font-color;

              ul {
                background-color: rgba(255, 255, 255, 0.1);
              }
            }

            a {
              position: relative;
            }
          }
        }
      }
    }

    .active {
      > button {
        &:after {
          transform: rotate(90deg);
        }
      }
    }
  }
}

.bootstrap-select > .dropdown-toggle {
  background-color: $light-bg !important;
  border: 1px solid rgba(96, 108, 56, 0.25);
  outline: none !important;
  box-shadow: none !important;
  color: rgba(30, 40, 30, 0.4) !important;
  font-size: 1.1rem;
  height: 47px;
  line-height: 47px;
  width: 100%;
  outline: 0 !important;
  padding: 0 1rem !important;
}

.bootstrap-select .dropdown-menu li a {
  font-size: 1.2rem !important;
}

.bootstrap-select {
  .dropdown-toggle::after {
    content: "";
    position: absolute;
    right: 1rem;
    top: 1.2rem;
    width: 8px;
    height: 8px;
    border: 2px solid $primary-color;
    border-top: 0;
    border-left: 0;
    transition: all .2s ease;
    transform-origin: center;
    transform-box: fill-box;
    transform: rotate(45deg);
  }
}

.dropdown-item.selected,
.dropdown-item:focus,
.dropdown-item:active {
  background-color: $primary-color;
  color: $white;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary-color;
  color: $white;
}

.bk-banner {
  @include mq(mmd) {
    padding: 2rem 0rem 0rem;
  }

  .h1 {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  .container {
    position: relative;

    @include mq(md) {
      &:after {
        content: "";
        position: absolute;
        left: 73%;
        top: 0;
        width: 100vw;
        height: 100%;
        background-color: $primary-color;
        z-index: -1;
      }
    }
  }

  .right-bk {
    position: relative;

    &:before {
      content: "";
      width: 437px;
      height: 437px;
      background-color: $secondary-color;
      border-radius: 50%;
      z-index: -1;
      position: absolute;
      left: 4rem;
      top: 7rem;
    }
  }

  .bk-quote {
    right: 0;
    top: 58%;
  }

  .follow-bk {
    margin-top: 3rem;

    a {
      text-decoration: underline;
      letter-spacing: 1px;
      font-size: 1.2rem;

      &:hover {
        color: $secondary-color;
      }
    }
  }
}

.bk-quote {
  background-color: $white;
  border-radius: $border-radius;
  padding: 3rem 2rem 2rem;
  position: absolute;
  max-width: 19rem;

  p {
    margin-bottom: 0;
    text-align: center;
    font-weight: 400;
  }

  &:before {
    content: url('./quote.svg');
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    background-color: $white;
    width: 47px;
    height: 47px;
    border-radius: 50%;
    position: absolute;
    top: -23px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bk-title {
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 1px;
}

.bk-services {
  display: flex;
  flex-direction: column;
  height: 100%;
  transition: all .2s ease;
  border-radius: $border-radius;

  @include mq(lg) {
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0);
    padding: 3rem 2rem;
    &:hover {
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    }
  }

  figure {
    margin-bottom: 2rem;
  }

  .h3 {
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 1.5rem;
  }

  .btn {
    margin-top: auto;
    width: 100%;
  }
}

.bk-about {
  .bk-quote {
    right: -25%;
    top: 55%;

    @include mq(mlg) {
      right: 0;
      top: inherit;
      bottom: 0;
    }
  }

  .left-img-bk {
    padding-bottom: 100%;
    @include mq(lg) {
      padding-bottom: 150%;
    }
  }

  .right-img-bk {
    margin-left: auto;

    @include mq(lg) {
      max-width: 23rem;
    }
  }
}

.bk-what-we-do {
  margin-top: -16rem;
  padding-top: 19rem;

  @include mq(sm) {
    margin-top: -23rem;
    padding-top: 27rem;
  }

  @include mq(md) {
    margin-top: -24rem;
  }
}

.bk-feature {
  text-align: center;
  color: $white;

  p {
    color: $white;
  }

  figure {
    margin-bottom: 2rem;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .h3 {
    font-size: 1.8rem;
  }
}

.bk-reach-us {
  .call-us-bk {
    &:hover {
      color: $secondary-color;
    }
  }

  .btn {
    padding: 1.1rem 3rem;

    &:hover,
    &:active,
    &:focus {
      background-color: $white;
      color: $primary-color;
    }
  }
}

.bk-slider-holder {
  padding: 0px 15px;
  @include mq(sm) {
    padding: 0px 0px;
    margin-left: calc(50% - 270px + 15px);
  }
  @include mq(md) {
    margin-left: calc(50% - 360px + 15px);
  }
  @include mq(lg) {
    margin-left: calc(50% - 480px + 15px);
  }
  @include mq(xl) {
    margin-left: calc(50% - 570px + 15px);
  }
  @include mq(w4) {
    margin-left: calc(50% - 650px + 15px);
  }
}

.bk-shop {
  text-align: center;

  figure {
    margin-bottom: 2rem;
  }

  .h4 {
    font-weight: 600;
    font-size: 1.6rem;
    margin-bottom: 0;

    @include mq(w4) {
      font-size: 1.8rem;
    }
  }
}

.bk-custom-slider-btn {
  width: 56px;
  height: 56px;
  background-color: rgba(248, 157, 42, .45);
  border: none;
  outline: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: all .2s ease;

  &:hover {
    background-color: $secondary-color;
  }
}

.bk-testimonial {
  .content-bk {
    background-color: $white;
    padding: 3rem 2rem;
    position: relative;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 0px solid transparent;
      border-right: 47px solid transparent;
      border-top: 36px solid $white;
      position: absolute;
      top: 100%;
      left: 3rem;
    }

    p {
      margin-bottom: 0;
    }

    .h4 {
      font-weight: 600;
      margin-bottom: 0;
      margin-right: auto;
      width: calc(100% - 100px);
    }

    p {
      color: $darkgray;
      font-weight: 400;
    }
  }

  .footer-bk {
    margin-top: 3rem;
    display: flex;
    align-items: center;

    figure {
      height: 54px;
      width: 54px;
      border: 3px solid $white;
      border-radius: 50%;
      padding-bottom: 0;
      overflow: hidden;
    }

    .brand-bk {
      padding-bottom: 2rem;
      margin-left: 1rem;

      img {
        object-position: left;
      }
    }
  }
}


.form-group {
  label {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    color: $black;
    text-transform: uppercase;
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid $black;
    outline: 0;

    &::placeholder {
      font-weight: 300;
    }
  }

  textarea {
    width: 100%;
    border: none;
    border-bottom: 1px solid $black;
    outline: 0;

    &::placeholder {
      font-weight: 300;
    }
  }
}

.bk-footer {
  .top-bk {
    background-color: $primary-color;
    color: $white;
    padding: 3rem 0rem;
    @include mq(lg) {
      padding: 4rem 0rem;
    }

    a {
      color: $white;
      font-size: 1rem;

      &:hover {
        color: $secondary-color;
      }
    }

    .btn {
      color: $primary-color;
      font-weight: 600;
      width: 100%;
      border-radius: 0;

      &:hover {
        color: $white;
      }
    }

    ul {
      padding-left: 0;
      margin-bottom: 0;
    }

    li {
      list-style: none;
      margin-bottom: 0.3rem;

      &:last-child {
        margin-bottom: 0;
      }

      address {
        font-size: 1rem;
      }
    }

    .icon-bk {
      width: 16px;
      display: inline-flex;
      margin-right: 0.5rem;
    }
  }

  .title-bk {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }

  .bottom-bk {
    background-color: $primary-color;
    padding: 1.5rem;
    border-top: 1px solid $white;

    p {
      color: $white;
      margin-bottom: 0;
    }

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;

      @include mq(lg) {
        justify-content: flex-end;
      }
    }

    a {
      color: $white;

      &:hover {
        color: $secondary-color;
      }
    }

    li {
      margin-right: 8px;
      padding-right: 8px;
      position: relative;
      list-style: none;

      &:last-child {
        margin-right: 0;
        padding-right: 0;

        &:after {
          display: none;
        }
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 9px;
        width: 1px;
        height: 12px;
        background-color: $white;
      }
    }
  }
}

.bk-faq-header {

  .large-title {
    margin-bottom: 2rem;
  }

  p {
    margin-bottom: 2rem;
    color: $primary-color;
  }

  .form-group {
    max-width: 20rem;
    margin: 0 auto;

    input {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.5);
      border-radius: 0;
      background: url('../assets/grey-search.svg') no-repeat 3px center / 20px;
      padding-left: 2rem;

      &::placeholder {
        font-weight: 300;
      }
    }
  }
}

.bk-accordion {
  .card {
    border: none;
    border-bottom: 1px solid #191919;
    border-radius: 0;
    margin-bottom: 2rem;
    background-color: transparent;
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    border: 0;

    .btn {
      background-color: transparent;
      padding: 0rem 2rem 1.2rem 0rem;
      height: auto;
      width: 100%;
      border: none;
      border-radius: 0;
      text-align: left;
      color: $primary-color;
      text-decoration: none;
      border: 0;
      line-height: 1.6rem;
      justify-content: flex-start;
      font-weight: 600;
      font-size: 1.15rem;

      &:after {
        content: "";
        position: absolute;
        right: 2px;
        top: 8px;
        width: 8px;
        height: 8px;
        border: 1px solid $primary-color;
        border-top: 0;
        border-left: 0;
        transform: rotate(-135deg);
        transition: all .2s ease;
        transform-origin: center;
        transform-box: fill-box;
      }

      &.collapsed {
        font-weight: 300;

        &:after {
          transform: rotate(45deg);
        }
      }
    }
  }

  .card-body {
    padding: 0 0 2rem 0;
  }
}

.bk-inner-banner {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    height: 62%;
    width: 20rem;
    background-color: $secondary-color;
    bottom: 0;
    transform: skew(-40deg, 0deg);
    right: -49%;
    @include mq(sm) {
      right: -22%;
    }
    @include mq(md) {
      right: 0;
      height: 100%;
    }
    @include mq(lg) {
      width: 34rem;
    }
  }

  .right-bk {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 84px;
      height: 44px;
      background: url("./shape.svg") no-repeat center / contain;
      right: -4rem;
      top: 0;
    }
  }

  .shop-banner-right {
    position: relative;

    @include mq(lg) {
      margin-bottom: -7px;
    }
  }
}

.blog-filter {
  .dropdown-toggle {
    background-color: transparent !important;
    border: none;
    font-size: 1rem;
    color: $primary-color !important;
    outline: 0;
    padding: 0 !important;
  }

  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: auto;
  }

  .bootstrap-select .dropdown-toggle:focus, .bootstrap-select > select.mobile-device:focus + .dropdown-toggle {
    outline: 0 !important;
  }

  .dropdown-menu {
    min-width: 208px !important;
  }
}

.bk-blog {

  figure.bk-obj-cover {
    padding-bottom: 60%;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 2rem;
  }

  .title-bk {
    font-size: 1.5rem;
    margin-bottom: 0;
    width: calc(100% - 20px);
    padding-right: 0.7rem;

    @include mq(md) {
      font-size: 1.9rem;
    }
  }

  p {
    color: $darkgray;
    font-weight: 400;
  }

  .link-bk {
    font-weight: 400;
    color: $secondary-color;

    &:hover {
      color: $primary-color;
    }
  }

  .footer-bk {
    display: flex;
    align-items: center;
    margin-top: 1rem;

    figure {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      margin-right: 1rem;

      img {
        object-position: center;
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    span {
      font-weight: 600;
    }
  }
}

.bk-connect {
  padding-top: 2rem;
  @include mq(md) {
    padding-top: 4rem;
  }

  .icon-bk {
    display: inline-flex;
    margin-right: 1rem;
  }

  a,
  address {
    font-weight: 400;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: $secondary-color;
    }
  }
}

.bk-custom-checkbox {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: $secondary-color;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $border;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 8px;
      top: 4px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  &.bk-custom-checkbox2 {
    font-weight: 400;
    font-size: 1rem;

    input {
      &:checked ~ .checkmark {
        background-color: rgba(248, 157, 42, 0.1);
        border-color: $secondary-color;

        &:after {
          border-color: $secondary-color;
        }
      }
    }

    .checkmark {
      background-color: transparent;
      border: 1px solid $darkgray;
      top: 3px;

      &:after {
        top: 3px;
        left: 7px;
      }
    }
  }
}

.bk-custom-radio {
  display: block;
  position: relative;
  padding-left: 32px;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: rgba(248, 157, 42, 0.1);
      border-color: $secondary-color;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 20px;
    width: 20px;
    border: 1px solid $darkgray;
    border-radius: 50%;

    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
      background-color: $secondary-color;
      border-radius: 50%;
    }
  }
}

.bk-services-wrapper {
  .bk-feature {
    max-width: 10rem;
    margin: 0 auto;

    figure {
      height: 62px;
    }

    h3 {
      font-size: 1.5rem;
      font-weight: 500;
    }

    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }
}

.bk-list-style {

  ul {
    padding-left: 1rem;
  }

  li {
    margin-bottom: 0.7rem;

    &::marker {
      margin-right: 1rem;
      color: $secondary-color;
      font-size: 1.1rem;
    }
  }
}

.bk-product-top {
  padding-bottom: 2rem;
  border-bottom: 1px solid $primary-color;
}

.bk-product-search {
  margin-right: 2.5rem;
  position: relative;

  .btn-primary {
    background-color: transparent !important;
    outline: 0;
    border: none;
    box-shadow: none;
    display: inline-block;
  }

  .collapse,
  .collapsing {
    position: absolute;
    top: calc(100% + 1.2rem);
    left: 0;
    width: 20rem;

    @include mq(x4) {
      width: 24rem;
    }

    @include mq(md) {
      top: calc(100% + 0.7rem);
      right: 0;
      left: inherit;
    }
  }

  .card {
    width: 100%;
    padding: 2rem;
    background-color: $gray;
    border: none;
    z-index: 2;
  }

  .form-group {
    margin-bottom: 0;

    input {
      width: 100%;
      height: 45px;
      border: 1px solid $border-color;
      padding: 0 1rem;
      font-size: 1.1rem;
    }
  }
}

.bk-product-sort {

  .dropdown-toggle {
    background-color: transparent !important;
    border-radius: 0;
    color: $primary-color !important;
    padding-left: 3.5rem !important;

    &:before {
      content: "";
      background: url('./sort-icon.svg') no-repeat center 10px / 19px;
      width: 26px;
      height: 30px;
      position: absolute;
      top: 7px;
      left: 1rem;
    }
  }

  .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 230px;
    @include mq(x4) {
      width: 247px;
    }
  }
}

.bk-product-filter {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include mq(lg) {
    border-right: 1px solid $primary-color;
  }

  .card {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid $primary-color;
    margin-bottom: 1rem;
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    border: 0;
    margin-bottom: 1rem;
  }

  .btn-link {
    width: 100%;
    border: none;
    border-radius: 0;
    outline: 0;
    background-color: transparent;
    text-align: left;
    padding: 0 2rem 0 0;
    font-size: 1.15rem;
    font-weight: 600;
    text-decoration: none;
    position: relative;

    &.collapsed {
      &:after {
        transform: rotate(0deg);
      }
    }

    &:after {
      content: "";
      background: url('./toggle-arrow.svg') no-repeat center / 14px;
      height: 10px;
      width: 16px;
      position: absolute;
      right: 0;
      top: 10px;
      transform: rotate(-180deg);
      transition: all .2s ease;
    }
  }

  .card-body {
    padding: 0 0 1rem 0;
  }
}

.bk-product {
  background-color: $gray;
  padding: 1.5rem;
  height: 100%;
  border-radius: $border-radius;

  a {
    &:hover {
      .title-bk {
        color: $secondary-color;
      }
    }
  }

  figure {
    padding-bottom: 70%;
    margin-bottom: 2rem;
  }

  .title-bk {
    font-size: 1.2rem;
    margin-bottom: 0;
    line-height: 1.25;
    width: calc(100% - 24px);
    padding-right: 1rem;
    transition: all .2s ease;

    @include mq(xl) {
      font-size: 1.4rem;
    }
  }
}

.bk-product-single-slider {
  .slider-for {
    margin-bottom: 1.2rem;

    .slick-slide {
      height: 320px;
      width: 100%;
      background-color: $gray;
      border-radius: 4px;
      overflow: hidden;

      @include mq(sm) {
        height: 340px;
      }
      @include mq(lg) {
        height: 460px;
      }

      figure {
        width: 100%;
        height: 100%;
      }

      img {
        object-position: center;
        object-fit: contain;
        width: 100%;
        height: 100%;
        width: 100%;
      }
    }

  }

  .slider-nav {

    .slick-list {
      overflow: hidden;
      margin: 0 -10px;
    }

    .item {
      padding: 0 10px;

      &.slick-current {
        figure {
          border: 1px solid $primary-color;
        }
      }
    }

    figure {
      margin: 0;
      padding-bottom: 100%;
      width: 100%;
      background-color: $gray;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

.bk-list {
  ul {
    padding-left: 0;
    margin-bottom: 0;
  }

  li {
    list-style: none;
    margin-bottom: 0.7rem;
    position: relative;
    padding-left: 1.7rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: "";
      width: 7px;
      height: 7px;
      background-color: $secondary-color;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 11px;
    }
  }
}

.bk-product-detail-footer {
  max-width: 29rem;

  .btn {
    width: 100%;
  }

  .btn-border {
    color: $secondary-color;
    border-color: $secondary-color;

    &:hover,
    &:active,
    &:focus {
      color: $white;
      border-color: $primary-color;
    }
  }
}

.datepicker {
  background: url("./calender.svg") no-repeat 100% center / 16px;
}

.ui-datepicker {
  .ui-datepicker-prev span {
    background: url("./left-arrow.svg") no-repeat center / 7px;
  }

  .ui-datepicker-next span {
    background: url("./right-arrow.svg") no-repeat center / 7px;
  }
}

.bk-item-details {
  position: relative;

  .btn-primary{
    width: 100%;
    background-color: transparent !important;
    text-align: left;
    border: none;
    border-bottom: 1px solid $black;
    padding: 0;
    box-shadow: none !important;
    outline: 0;
    font-size: 0;
    height: 25px;
    position: relative;

    &:after{
      content: "";
      position: absolute;
      right: 2px;
      top: 2px;
      width: 8px;
      height: 8px;
      border: 1px solid $primary-color;
      border-top: 0;
      border-left: 0;
      transform: rotate(-135deg);
      transition: all .2s ease;
      transform-origin: center;
      transform-box: fill-box;
    }

    &.collapsed{
      &:after{
        transform: rotate(45deg);
      }
    }
  }

  .card{
    padding: 1.6rem;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
    border-radius: $border-radius;
    border: none;

    .title-bk{
      font-size: 1.1rem;
      width: calc(100% - 78px);
      padding-right: 1rem;
      margin-bottom: 0;
      font-weight: 300;
    }

    input{
      width: 78px;
      height: 30px;
      border-radius: $border-radius;
      padding: 0 .4rem;
      outline: 0;
      border: 1px solid $primary-color;
      color: $primary-color;
    }

    .js-input-select{
      background-color: $secondary-color;
      border: 1px solid $secondary-color;

      &::placeholder{
        color: $white;
      }
    }
  }
}
